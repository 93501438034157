import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Loading from "./Loading";
function User({ singleUser, user, loading, repos, getRepos }) {
    const { username } = useParams();
    const { name, avatar_url, bio, hireable, following, followers, company, location, blog } = user;
    useEffect(() => {
        singleUser(username);
        getRepos(username);
        // eslint-disable-next-line
    }, []);
    if (loading) {
        return <Loading />
    } else {
        return (
            <div className="row">
                <div className="side-2">
                    <Link to="/">Go Back</Link>
                    <h2>{name}</h2>

                    <img src={avatar_url} height={200} alt="profilepic" />
                    <h3>Bio</h3>
                    <p style={{ color: "black", textAlign: "left", fontSize: "large" }}>{bio}</p>
                    <a href={`https://github.com/${username}`} >Go to GitHub Profile</a>
                    <div className="fakeimg" style={{ height: "60px" }}> Followers : {followers}  
                    &nbsp;&nbsp;| Following : {following}</div><br />
                    <div className="fakeimg" style={{ height: "60px" }}>Hireable : {hireable ? "Open" : "No"}</div><br />

                    {company && (
                        <>
                            <div className="fakeimg" style={{ height: "60px" }}>Company : {company}</div><br />
                        </>
                    )}
                    {location && (
                        <>
                            <div className="fakeimg" style={{ height: "60px" }}>Location : {location}</div><br />
                        </>
                    )}
                    {blog && (
                        <>
                            <div className="fakeimg" style={{ height: "60px" }}>Website : {blog}</div><br />
                        </>
                    )}
                </div>
                <div className="main">
                    {repos.map((ele, i) => (
                        <div className="repos-class" key={i}>
                            <a href={ele.html_url} target='_blank' rel="noreferrer" className="repo-link">
                                <h2>{ele.name}</h2>
                            </a>
                            <p style={{ color: "black" }}>{ele.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

}

export default User;
import './App.css';
import Home from './components/Home';
import User from './components/User';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Routes,
  Route,
} from "react-router-dom";
import Footer from './components/Footer';
function App() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(null);
  const [alert, setAlert] = useState(null);
  const [repos, setRepos] = useState([]);
  //clearUsers 
  const clearUsers = (e) => {
    e.preventDefault();
    setUsers([]);
  }
  //searchUsers
  const searchUsers = async (username) => {
    try {
      setLoading(true);
      const { data } = await axios.get(`https://api.github.com/search/users?q=${username}`, {
        auth: {
          username: "adnanali-in",
          password: "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",
        },
      });
      setLoading(false);
      setUsers(data.items);
    } catch (error) {
      console.log(error.response.data);
    }
  }
  //showAlert
  const showAlert = (alertdata) => {
    setAlert(alertdata);
    setTimeout(() => {
      setAlert(null);
    }, 3500);
  }
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await axios.get('https://api.github.com/users', {
          auth: {
            username: "adnanali-in",
            password: "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",
          },
        });
        setUsers(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  //singleUser
  const singleUser = async (username) => {
    try {
      setLoading(true);
      const { data } = await axios.get(`https://api.github.com/users/${username}`, {
        auth: {
          username: "adnanali-in",
          password: "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",
        },
      });
      setUser(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }
  //getRepos
  const getRepos = async (username) => {
    try {
      setLoading(true);
      const res = await axios.get(`https://api.github.com/users/${username}/repos?sort=desc&per_page=5`, {
        auth: {
          username: "adnanali-in",
          password: "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",
        },
      })
      setRepos(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Home
          users={users}
          loading={loading}
          clearUsers={clearUsers}
          searchUsers={searchUsers}
          alert={alert}
          showAlert={showAlert}
        />} />
        <Route path="/user/:username" element={<User
          singleUser={singleUser}
          user={user}
          loading={loading}
          repos={repos}
          getRepos={getRepos}
        />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;


//Layout Link : https://www.w3schools.com/css/tryit.asp?filename=trycss_mediaqueries_website
function Alert({ alert }) {
    return (
        <>
            {alert !== null && (
                <>
                    <center>
                        <div className={`alert-${alert.type}`}>{alert.msg}</div>
                    </center>
                </>
            )}
        </>
    )
}

export default Alert;
import { useState } from "react";
import Alert from "./Alert";
function Search({ clearUsers, searchUsers, alert, showAlert }) {
    const [username, setUsername] = useState("");
    const onChangeHandler = (e) => {
        setUsername(e.target.value)
    }
    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (username === '') {
            return showAlert({
                type: "error",
                msg: "Username cannot be empty"
            });

        }
        searchUsers(username);
        setUsername("");
    }
    return (
        <div className="header">
            <a href="/" style={{ color: "white" }}>
                <i className="fa-brands fa-square-github fa-5x"></i>
            </a>

            <h1> Github Search Engine</h1>
            <Alert alert={alert} />
            <form>
                <input type="text"
                    name="username"
                    value={username}
                    onChange={onChangeHandler}
                    placeholder="Search Users ..."
                />
                <div style={{ marginTop: "2%" }}>
                    <button onClick={onSubmitHandler}> Submit</button>
                    <button onClick={clearUsers}>Clear</button>
                </div>
            </form>
        </div>
    )
}

export default Search;